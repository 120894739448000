<template>
  <footer>
    <div class="footer">
      <section class="logo-sec">
        <img src="@/assets/footerLogo.png" alt="">
        <span>Augmented Knowledge Corp. All rights reserved.</span>
      </section>
      <section class="link-sec">
        <a href="/pricing">Pricing</a>
        <a href="/contact">Contact</a>
        <a href="/api/public/brochure_eng_AK_eng.pdf">Brochure</a>
      </section>
    </div>
  </footer>
</template>

<style lang="scss" scoped>
footer {
  background: #222222;
  padding: 30px;
  height: 100%;

  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo-sec {
      display: flex;
      align-items: center;

      span {
        color: #CBCBCB;
        margin-left: 40px;
      }
    }

    .link-sec {

      a {
        text-decoration: none;
        color: #fff;
        margin-left: 15px;
        font-size: 14px;

        &:hover {
          opacity: 0.75;
        }
      }
    }
  }
}

@media (max-width: 1020px) {
  footer {
    height: 100%;

    .footer {
      display: block !important;

      .logo-sec {
        display: block;

        img {
          padding-bottom: 30px;
          margin: 0 auto;
          display: block;
        }

        span {
          text-align: center;
          display: block;
          margin: 0;
        }
      }

      .link-sec {
        display: flex;
        justify-content: center;
        padding-top: 20px;
      }
    }
  }
}


@media (max-width: 500px) {
  footer {
    height: 100%;

    .footer {
      display: block !important;

      .logo-sec {
        display: block;

        img {
          padding-bottom: 30px;
          margin: 0 auto;
          display: block;
        }

        span {
          text-align: center;
          display: block;
          margin: 0;
        }
      }

      .link-sec {
        display: flex;
        justify-content: center;
        padding-top: 20px;
      }
    }
  }
}
</style>
