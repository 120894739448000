import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { useStore } from "@/store";

import Home from "@/views/Home.vue";
import AircraftsPage from "@/views/AircraftsPage.vue";
import AircraftComponentsPage from "@/views/AircraftComponentsPage.vue";
import VideoView from "@/views/VideoView.vue";
import EmbedVideoPlayer from "@/components/EmbedVideoPlayer";
import VideoUpload from "@/views/VideoUpload.vue";
import AnnotationTool from "@/views/AnnotationTool.vue";
import SignInPage from "@/views/SignInPage.vue";
import SignUpPage from "@/views/SignUpPage.vue";
import SignOutPage from "@/views/SignOutPage.vue";
import EditProfilePage from "@/views/EditProfilePage.vue";
import PrivacyPolicyAgreement from "@/views/PrivacyPolicyAgreement.vue";
import PricingPage from "@/views/PricingPage.vue";
import MRDemoVideoPage from "@/views/MRDemoVideoPage.vue";
import ContactUSPage from "@/views/ContactUSPage.vue";
import EmailVerifyPage from "@/views/EmailVerifyPage.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { unauthorized: true, title: "AKMRO - Home" },
  },
  {
    path: "/aircrafts",
    name: "Aircrafts",
    component: AircraftsPage,
    meta: { title: "AKMRO - Aircrafts" }
  },
  {
    path: "/aircraft/:model",
    name: "AircraftComponentsPage",
    component: AircraftComponentsPage,
    props: true,
    meta: { title: "AKMRO - {{}} Components" }
  },
  {
    path: "/watch/:videoId(.*)",
    name: "VideoView",
    component: VideoView,
    props: true,
    meta: { title: 'AKMRO - AMM {{}}' },
  },
  {
    path: "/embed/:videoId(.*)",
    name: "EmbedVideoView",
    component: EmbedVideoPlayer,
    props: true,
    meta: { layout: "FullContentLayout", title: 'AKMRO - AMM {{}}' },
  },
  {
    path: "/upload",
    name: "VideoUpload",
    component: VideoUpload,
  },
  {
    path: "/edit/:id(.*)",
    name: "AnnotationTool",
    component: AnnotationTool,
    props: true,
  },
  {
    path: "/signin",
    name: "SignInPage",
    component: SignInPage,
    meta: { unauthorized: true, title: "AKMRO - Sign In" },
  },
  {
    path: "/signout",
    name: "SignOutPage",
    component: SignOutPage,
    meta: { layout: "FullContentLayout", title: "AKMRO - Sign Out" },
  },
  {
    path: "/signup",
    name: "SignUpPage",
    component: SignUpPage,
    meta: { unauthorized: true, title: "AKMRO - Sign Up" },
  },
  {
    path: "/editprofile",
    name: "EditProfilePage",
    component: EditProfilePage,
    meta: { title: "AKMRO - Edit Profile" },
  },
  {
    path: "/privacy",
    name: "PrivacyPolicyPage",
    component: PrivacyPolicyAgreement,
    meta: { unauthorized: true, layout: "FullContentLayout", title: "AKMRO - Privacy Policy" },
  },
  {
    path: "/pricing",
    name: "PricingPage",
    component: PricingPage,
    strict: true,
    meta: { unauthorized: true, title: "AKMRO - Pricing" },
  },
  {
    path: "/pricing/mr-demo-video",
    name: "MRDemoVideoPage",
    component: MRDemoVideoPage,
    strict: true,
    meta: { unauthorized: true, title: "AKMRO - MR Demo Video" },
  },
  {
    path: "/contact",
    name: "ContactUSPage",
    component: ContactUSPage,
    meta: { unauthorized: true, title: "AKMRO - Contact Us" },
  },
  {
    path: "/verify-email",
    name: "EmailVerify",
    component: EmailVerifyPage,
    strict: true,
    meta: { unauthorized: true, title: "AKMRO - Verify Email" },
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.meta.unauthorized === true) {
    next();
    return;
  }

  const store = useStore();
  if (store.user === null) {
    next("/signin");
    return;
  }

  if (store.user.verified === false) {
    if (/\/watch\/.+$/.test(to.path)) {
      next({ path: "/verify-email", query: { ...to.query, redirectFrom: to.path } });
      return;
    }
  }

  if (to.meta.title) {
    let title = to.meta.title as string;
    if (/^\/(watch|embed)\//.test(to.path)) {
      title = title.replace("{{}}", to.params.videoId as string);
    }
    if (/^\/aircraft\//.test(to.path)) {
      title = title.replace("{{}}", to.params.model as string);
    }
    document.title = title;
  }

  next();
});

export default router;
