<template>
  <div class="video-wrapper">
    <div class="video-window" :style="{
      width: videoComponentWidth + 'px',
      height: videoComponentHeight + 'px',
    }">
      <video class="overlay" controlsList="nodownload" ref="videoComponent" @loadedmetadata="onLoadedMetadata"
        @click="annotationHandler">
        <source :src="`${videoURL}?token=${userToken}`" :type="videoType" />
      </video>
      <div class="overlay" v-show="overlayEnabled" :style="{ pointerEvents: 'none' }">
        <div class="annotation">
          <div v-for="(item, key) in annotationRects" :key="key" class="overlay-tag" :style="item.style"
            @click="() => showAnnotation(item)" @error="hideIcon">
            <img v-if="item.src && item.src.length > 0" :src="item.src" />
            <h5 v-if="!item.src">{{ item.label }}</h5>
          </div>
          <div class="subtask-list-button" @click="toggleSubTaskList">
            <transition name="fade">
              <SubTaskList v-show="subTaskListVisible" :model="model" :currentTask="taskId" :embed="true"
                class="subtask-list" />
            </transition>
          </div>
        </div>
      </div>
      <seeker-bar class="seeker-bar" @seek="seek" :annotation="annotation" :currentTime="currentTime" :duration="duration"
        :width="videoComponentWidth" />
      <transition name="fade">
        <div :class="['popup', popupPosition]" ref="overlayPopUpDiv" v-show="overlayPopupVisible && overlayEnabled">
          <div class="popup-content" v-html="overlayPopup"></div>
          <button v-if="overlayPosition !== 'fixed'" class="close-button" @click="hideAnnotation">
            CLOSE
          </button>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { useStore } from "@/store";
import { mapState } from "pinia";
import { api } from "@/service/api";
import router from "@/router";

import SeekerBar from "./SeekerBar.vue";
import SubTaskList from "../SubTaskList.vue";

export default {
  components: {
    SeekerBar,
    SubTaskList,
  },
  props: ["videoId", "width"],
  emits: ["resize", "timeupdate"],
  data() {
    return {
      videoWidth: 320,
      videoHeight: 240,
      videoScale: 1.0,
      currentTime: 0,
      duration: 0,

      model: "",
      taskId: "",
      title: "[Untitled]",
      annotation: {},
      annotationRects: {},
      tickTimer: null,
      overlayPosition: "float",

      currentShowingAnnotationId: null,
      overlayPopup: "",
      overlayPopupVisible: false,
      // overlayEnabled: true,

      // SubTaskList
      subTaskListVisible: false,
    };
  },

  computed: {
    userToken() {
      return window.sessionStorage.getItem("token");
    },

    videoURL() {
      return process.env.BASE_URL + `api/video/${this.videoId}`;
    },

    videoType() {
      return "video/mp4";
    },

    videoComponentWidth() {
      return Math.min(this.width, window.innerWidth);
    },

    videoComponentHeight() {
      return Math.min(this.videoHeight * this.videoScale, window.innerHeight);
    },

    popupPosition() {
      return `popup-${this.overlayPosition}`;
    },

    ...mapState(useStore, { overlayEnabled: "showAnnotation " }),
  },

  watch: {
    videoId() {
      this.$refs.videoComponent.load();
      this.loadAnnotation();
    },

    width() {
      this.adjustWindow();
    },
  },

  methods: {
    onLoadedMetadata() {
      this.duration = this.$refs.videoComponent.duration;
      this.adjustWindow();
    },

    async loadAnnotation() {
      this.annotation = (
        await api.get(`/video/${this.videoId}/annotations`)
      ).data;

      // Common
      const [model, taskId] = this.videoId.split("/");
      this.title = `[${model}] ${taskId}`;
      // this.title = this.annotation?.header?.title ?? "[Untitled]";
      this.model = model;
      this.taskId = taskId;
      this.overlayPosition =
        this.annotation?.header?.overlayPosition ?? "float";

      if (this.tickTimer) window.cancelAnimationFrame(this.tickTimer);

      // Version 2
      if (this.annotation?.header?.version >= 2) {
        this.tickTimer = window.requestAnimationFrame(this.tickV2);
      }
    },

    adjustWindow() {
      const componentWidth = this.videoComponentWidth;
      const componentHeight = this.videoComponentHeight;
      this.videoWidth = this.$refs.videoComponent.videoWidth || 0;
      this.videoHeight = this.$refs.videoComponent.videoHeight || 0;

      this.videoScale = Math.max(
        componentWidth / (this.videoWidth + 0.001),
        componentHeight / (this.videoHeight + 0.001)
      );
    },

    hideIcon(e) {
      e.target.style.visibility = "hidden";
    },

    async showAnnotation(item) {
      if (this.currentShowingAnnotationId === item.label) return;
      console.log(`Load Annotation`, item);
      this.currentShowingAnnotationId = item.label;
      const video = this.$refs.videoComponent;
      video.pause();
      const res = await api.get(
        `/video/${this.videoId}/annotation/${item.content}`
      );

      if (res.status < 400) {
        const data = res.data;

        // Javascript execution override
        if (data.trim().startsWith("http")) {
          console.log("Direct link");
          let href = data;
          if (/\/watch\//.test(href)) {
            href = href.replace("/watch/", "/embed/");
          }
          window.location = href;
          return;
        } else if (data.trim().startsWith("/watch/")) {
          console.log("Direct link(relative)");
          // Workaround fix
          this.currentShowingAnnotationId = null;
          this.annotationRects = {};
          let href = data;
          if (/\/watch\//.test(href)) {
            href = href.replace("/watch/", "/embed/");
          }

          router.push(href);
          return;
        }

        this.overlayPopup = data;
        this.overlayPopupVisible = true;
      }
    },

    hideAnnotation() {
      this.overlayPopupVisible = false;
      this.currentShowingAnnotationId = null;
    },

    annotationHandler(e) {
      e.preventDefault();
      const video = this.$refs.videoComponent;

      this.playPause();
    },

    tickV2() {
      const { currentTime } = this.$refs.videoComponent;
      this.currentTime = currentTime;
      this.$emit("timeupdate", currentTime);

      const { metadataObject } = this.annotation;

      if (!metadataObject) {
        this.tickTimer = window.requestAnimationFrame(this.tick);
        return;
      }

      const inArr = _.filter(metadataObject, (o) => {
        return o.timeRange[0] <= currentTime && o.timeRange[1] >= currentTime;
      });
      const outArr = _.filter(metadataObject, (o) => {
        return o.timeRange[0] > currentTime || o.timeRange[1] < currentTime;
      });

      const { width: videoWidth, height: videoHeight } =
        this.$refs.videoComponent.getBoundingClientRect();

      // Incoming
      for (const inObj of inArr) {
        const src = inObj.img;
        const style = {
          position: "absolute",
        };

        if (src) {
          // No bounding box
          style.border = "none";
        }

        const i1 = _.findLastIndex(inObj.bbox, (o) => o[0] <= currentTime);
        const i2 = _.findIndex(inObj.bbox, (o) => o[0] >= currentTime);

        const [s1, [p1x1, p1y1], [p1x2, p1y2]] = inObj.bbox[i1];
        const [s2, [p2x1, p2y1], [p2x2, p2y2]] = inObj.bbox[i2];

        const t = (currentTime - s1) / (s2 - s1);

        const left = p1x1 + (p2x1 - p1x1) * t;
        const top = p1y1 + (p2y1 - p1y1) * t;
        const right = p1x2 + (p2x2 - p1x2) * t;
        const bottom = p1y2 + (p2y2 - p1y2) * t;
        const width = right - left;
        const height = bottom - top;

        style.left = `${left * videoWidth}px`;
        style.top = `${top * videoHeight}px`;
        style.width = `${width * videoWidth}px`;
        style.height = `${height * videoHeight}px`;

        this.annotationRects[inObj.id] = {
          src: (src && `${this.videoURL}/annotation/${src}`) || null,
          style: style,
          label: inObj.name,
          content: inObj.content,
        };
      }

      // Outgoing
      for (const outObj of outArr) {
        delete this.annotationRects[outObj.id];
      }

      this.tickTimer = window.requestAnimationFrame(this.tickV2);
    },

    seek(time) {
      this.$refs.videoComponent.currentTime = time;
    },

    playPause() {
      const video = this.$refs.videoComponent;

      if (video.paused) {
        video.play();
        if (this.overlayPosition !== "fixed") {
          this.hideAnnotation();
        }
      } else {
        video.pause();
      }
    },

    play() {
      const video = this.$refs.videoComponent;
      if (video) {
        video.play();
      }
    },

    pause() {
      const video = this.$refs.videoComponent;
      if (video) {
        video.pause();
      }
    },

    // SubTaskList
    toggleSubTaskList() {
      this.subTaskListVisible = !this.subTaskListVisible;
    },
  },

  async mounted() {
    this.$refs.videoComponent.disablePictureInPicture = true;
    await this.loadAnnotation();

    // Always popup show when overlayPosition is fixed
    if (this.overlayPosition === "fixed") {
      this.overlayPopupVisible = true;
    }
    if (this.annotation?.header?.overlayStyle) {
      const newStyle = this.annotation?.header?.overlayStyle;
      this.$refs.overlayPopUpDiv.style = newStyle;
    }
  },

  unmounted() {
    window.cancelAnimationFrame(this.tickTimer);
  },
};
</script>

<style lang="scss" scoped>
.video-wrapper {
  display: block;
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;

  .video-window {
    position: relative;
    width: 100%;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background-size: cover;
    overflow: hidden;
    object-fit: fill;

    img {
      object-fit: cover;
    }
  }

  .annotation {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: visible;

    .overlay-tag {
      /* border: 3px solid blue; */
      position: absolute;
      pointer-events: all;
      cursor: pointer;
      object-fit: contain;

      &:hover {
        border: 3px solid rgba(0, 0, 255, 0.3);
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    h5 {
      visibility: hidden;
      color: white;
      line-height: 1.5em;
      background-color: blue;
      margin: 0;
      padding: 0;
    }
  }

  .seeker-bar {
    position: absolute;
    width: 100%;
    height: 150px;
    left: 0;
    bottom: 0px;
    z-index: 10;
  }

  .popup-float {
    position: absolute;
    margin-left: auto;
    top: 10px;
    right: 10px;
    min-width: 100px;
    max-width: 50%;
    min-height: 30%;
    max-height: 90%;
    overflow: scroll;
    border: none;
    /* 2px dashed #ccc; */
    background: transparent;
    /* rgba(255, 255, 255, 0.8); */

    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .popup-fixed {
    position: absolute;
    width: 100%;
    height: 150px;
    left: 0;
    bottom: 1%;
    overflow: hidden;
    border: none;
    /* 2px dashed #ccc; */
    background: transparent;
    /* rgba(255, 255, 255, 0.8); */

    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .popup-content {
    display: block;
    overflow: hidden;
    width: 99%;
    height: 90%;
    border: 3px solid #333;
    border-radius: 10px;
    background-color: #ccc;
  }

  .popup-content :deep() .link-url {
    display: block;
    width: 100%;
    text-align: center;
    font-size: 25px;
    font-weight: bold;
    color: black;
    background-color: #eee;
  }

  .popup-content :deep() .banner {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }

  .popup :deep() iframe {
    width: 100%;
    height: 100%;
    overflow: visible;
  }

  .popup .close-button {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 80px;
    height: 30px;
    border: 3px solid red;
    color: red;
    background-color: white;
    z-index: 30;
    font-family: monospace;
    font-weight: bold;
    font-size: 18px;
    letter-spacing: 2px;
    text-align: center;
    margin: 0;
    padding: 1px;

    &:hover {
      color: white;
      background-color: red;
    }
  }
}

.info-wrapper {
  display: block;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
  {
  opacity: 0;
}

.inline {
  display: flex;
  justify-content: flex-start;
}

.edit-button {
  margin-left: 10px;
}

.subtask-list-button {
  position: absolute;
  box-sizing: border-box;
  bottom: 60px;
  right: 30px;
  width: 100px;
  height: 100px;
  /* border: 3px solid red; */
  pointer-events: all;
  cursor: pointer;
}

.subtask-list {
  position: absolute;
  pointer-events: all;
  box-sizing: border-box;
  bottom: 100px;
  right: 30px;
  width: 400px;
  height: 300px;
  border: 3px solid black;
  background: white;
  opacity: 0.8;
  z-index: 32;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
