import axios from 'axios';

declare module 'axios' {
  export interface AxiosRequestConfig {
    _retry?: boolean;
  }
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const BASE_URL = process.env.BASE_URL! + 'api';

export const apiWithoutToken = axios.create({
  baseURL: BASE_URL,
  validateStatus: () => true,
});

export const api = axios.create({
  baseURL: BASE_URL,
  validateStatus: () => true,
});

api.interceptors.request.use(async (config) => {
  const token = window.sessionStorage.getItem("token");
  config.headers.Authorization = token ? `Bearer ${token}` : null;

  return config;
});

api.interceptors.response.use(async (response) => {
  // If the response is 403 and we haven't already retried
  if ((response.status === 401 || response.status === 403) && !response.config._retry) {
    response.config._retry = true;

    // Try refreshing the token
    const refreshToken = window.localStorage.getItem("refreshToken");
    if (!refreshToken) {
      // If there's no refresh token, we can't proceed
      return {
        status: 403,
        statusText: 'Forbidden',
        data: { message: 'No refresh token found' }
      }
    }

    const refreshResponse = await axios.get(`${BASE_URL}/refresh`, {
      headers: {
        Authorization: `Bearer ${refreshToken}`
      }
    });

    if (refreshResponse.status !== 200) {
      // If the refresh fails for any reason, throw an error
      return {
        status: 403,
        statusText: 'Forbidden',
        data: { message: 'Token refresh failed' }
      }
    }

    const newToken = refreshResponse.data.token; // Assuming the token is returned in this manner
    window.sessionStorage.setItem('token', newToken);

    // Update the authorization header for the retry
    response.config.headers.Authorization = `Bearer ${newToken}`;

    // Retry the original request
    return api.request(response.config);
  }

  // If the response isn't a 403 or we've already retried, just return the original response
  return response;
});
