import { ref } from "vue";
import { defineStore } from "pinia";

function base64UrlDecode(str: string) {
  str = str.replace(/-/g, '+').replace(/_/g, '/');
  while (str.length % 4) {
    str += '=';
  }

  return decodeURIComponent(atob(str).split('').map(c => {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
}

function jwtDecode(token: string) {
  const [header, payload, signature] = token.split(".");
  if (!header || !payload || !signature) {
    throw new Error("Invalid JWT Token");
  }

  return JSON.parse(base64UrlDecode(payload));
}

export interface User {
  email: string;
  createdAt: Date;
  displayName?: string;
  roles: string[];
  company?: string;
  interest?: string;
  phone?: string;
  title?: string;
  verified: boolean;
  suspended: boolean;
};

export const useStore = defineStore(
  "store",
  () => {
    const user = ref<User | null>(null);
    const showAnnotation = ref<boolean>(true);
    const autoContinue = ref<boolean>(false);

    return { user, showAnnotation, autoContinue };
  },
  {
    persist: {
      enabled: true,
      strategies: [
        {
          key: "store",
          storage: localStorage,
        },
      ],
    },
  }
);
