<template>
  <div id="app-main">
    <section class="body">
      <slot />
    </section>
  </div>
</template>

<style lang="scss" scoped>
#app-main {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  section.body {
    width: 100%;
    height: 100%;
  }
}
</style>
