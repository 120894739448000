<template>
  <div class="body">
    <AlertDialog ref="alertDialog">
      <h2>Sign In Failed</h2>
      <p>Invalid email or password.</p>
    </AlertDialog>
    <div class="main-container">
      <section class="col signin-area">
        <h2>SIGN IN</h2>
        <form class="signin-form">
          <p>
            <label for="email">Email <b>*</b></label>
            <input v-model="formData.email" type="text" name="email" placeholder="E-MAIL" required />
          </p>
          <p>
            <label for="password">Password <b>*</b></label>
            <input v-model="formData.password" type="password" name="password" placeholder="PASSWORD" required />
          </p>
          <div class="sign-in-button">
            <button class="button" @click="signin">
              <span>SIGN IN</span>
            </button>
          </div>
        </form>
        <div class="signup-go">
          <span>Don’t have an account?</span>
          <a href="/signup">Sign up</a>
        </div>
      </section>
      <section class="singin-img">
        <img src="@/assets/signin.jpg" alt="">
      </section>
    </div>
  </div>
</template>

<script>
import { useStore } from "@/store";
import { api, apiWithoutToken } from "@/service/api";
import router from "@/router";

import AlertDialog from "@/components/AlertDialog.vue";

export default {
  name: "SignInPage",
  props: [],
  emits: [],
  components: {
    AlertDialog,
  },
  data() {
    return {
      formData: {
        email: "",
        password: "",
      },
    };
  },
  methods: {
    async login(email, password) {
      if (process.env["NODE_ENV"] === "standalone") {
        const store = useStore();
        store.user = {
          email: "admin@localhost",
          role: "admin",
        };

        router.push("/");
      } else {
        const res = await apiWithoutToken.post("/login", {
          email,
          password,
        });

        if (res.status === 200) {
          const { token, refreshToken } = res.data;
          window.sessionStorage.setItem("token", token);
          window.localStorage.setItem("refreshToken", refreshToken);
          const store = useStore();
          const profileQueryResponse = await api.get("/profile");
          store.user = profileQueryResponse.data || null;

          router.push("/");
        } else {
          let message = "Unknown error";
          if (typeof res.data === "string") {
            message = res.data;
          } else {
            message = res.data.message;
          }

          if (message === "User not found") {
            this.$refs.alertDialog.open();
            return;
          } else if (message === "Wrong password") {
            this.$refs.alertDialog.open();
            return;
          } else if (message === "No refresh token found" || message === "Account Not Verified") {
            router.push({ path: "/verify-email", query: { email: email } });
            return;
          }

          this.$refs.alertDialog.open();
        }
      }

      return false;
    },
    async signin(e) {
      e.preventDefault();
      await this.login(this.formData.email, this.formData.password);
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.main-container {
  display: flex;
}

button {
  border-radius: 50px;
  background-color: white;
  border: 1px solid #0F0E4C;
  color: #0F0E4C;
  text-align: center;
  font-size: 16px;
  padding: 10px;
  width: 150px;
  transition: all 0.5s;
  cursor: pointer;
  margin: 30px 0;
  font-weight: 700;
  cursor: pointer;
}

.col {
  width: 50%;
  padding: 0 100px;
  margin-top: 120px;

  .signup-go {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    text-decoration: none;
    padding: 0 20px;
    margin-top: 20px;
    text-align: end;
    color: #bbb;

    a {
      color: #4d4d4d;
    }
  }

  h2 {
    color: #0F0E4C;
    font-size: 40px;
    font-weight: 700;
  }

  p {
    width: 100%;
    padding-top: 10px;

  }

  label {
    display: block;
    color: #222;
    font-size: 18px;
    font-weight: 500;

    b {
      color: #F00;
      font-size: 15px;
      font-weight: 500;
    }

    @media (min-width: 1024px) {}

    &.privacy-policy-agreement {
      padding: 0 5px;
      text-overflow: ellipsis;
      font-size: 1em !important;
    }
  }

  input,
  select {
    margin-top: 5px;
    padding: 10px 5px;
    display: block;
    width: 100%;
    font-size: 16px;
    border: none;
    border-bottom: 1px solid #bbb;

    &::placeholder {
      @extend %my-placeholder;
    }
  }
}

%my-placeholder {
  color: #9E9E9E;
  font-size: 14px;
  font-weight: 400;
}

.singin-img {
  width: 50%;

  img {
    width: 100%;
    height: 100%;
  }
}

.signin-area {

  .signin-form {
    background: #FFF;
    padding: 60px;
    box-shadow: 2px 2px 15px 0px rgba(0, 0, 0, 0.10);
  }

  .sign-in-button {
    display: flex;
    justify-content: space-around;
    align-items: center;

    button {
      width: 100%;
    }
  }
}

footer {
  width: 100%;
  height: 5%;
  text-align: center;
  font-family: sans-serif;
  font-size: 16px;
  color: #333;
}

@media (max-width: 1024px) {
  .singin-img {
    display: none;
  }

  .col {
    width: 100%;
    padding: 0 20px;
    margin: 60px 0;
  }
}
</style>
