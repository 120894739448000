<template>
  <section class="main">
    <h2>Privacy Policy Agreement</h2>
    <p>
      This privacy policy will help you understand how Augmented Knowledge
      Corp.(AK Corp.) uses and protects the data you provide to us when you
      visit and use our demo service.
    </p>

    <p>
      We reserve the right to change this policy at any given time, of which you
      will be promptly updated. If you want to ensure that you are up to date
      with the latest changes, we advise you to visit this page frequently.
    </p>

    <h4>What User Data We Collect</h4>

    <p>When you visit the website, we may collect the following data:</p>

    <ul>
      <li>Your IP address.</li>
      <li>Your contact information and email address.</li>
      <li>Other information such as interests and preferences.</li>
    </ul>
    <h4>Why We Collect Your Data</h4>

    <p>We are collecting your data for several reasons:</p>

    <ul>
      <li>To better understand your needs.</li>
      <li>To improve our services and products.</li>
      <li>
        To send you promotional emails containing the information we think you
        will find interesting.
      </li>
      <li>
        To contact you to fill out surveys and participate in other types of
        market research.
      </li>
      <li>
        To customize our website according to your online behavior and personal
        preferences.
      </li>
    </ul>
    <h4>Safeguarding and Securing the Data</h4>

    <p>
      AK Corp. is committed to securing your data and keeping it confidential.
      AK Corp. has done all in its power to prevent data theft, unauthorized
      access, and disclosure by implementing the latest technologies and
      software, which help us safeguard all the information we collect online.
    </p>

    <h4>Our Cookie Policy</h4>

    <p>
      Once you agree to allow our website to use cookies, you also agree to use
      the data it collects regarding your online behavior (analyze web traffic,
      web pages you spend the most time on, and websites you visit).
    </p>

    <p>
      The data we collect by cookies is used to customize our website to your
      needs. After using the data for statistical analysis, the information is
      completely removed from our systems.
    </p>

    <p>
      Please note that cookies don’t allow us to gain control of your computer
      in any way. They are strictly used to monitor which pages you find useful
      and which you do not to provide a better experience for you.
    </p>

    <p>
      If you want to disable cookies, you can do it by accessing your internet
      browser settings. (Provide links for cookie settings for major internet
      browsers).
    </p>

    <h4>Restricting the Collection of your Personal Data</h4>

    <p>
      You might wish to restrict the use and collection of your data at some
      point. You can achieve this by doing the following:
    </p>
    <ul>
      <li>
        When filling the forms on the website, make sure to check if there is a
        box you can leave unchecked if you don’t want to disclose your personal
        information.
      </li>
      <li>
        If you have already agreed to share your information with us, feel free
        to contact us via email, and we will be more than happy to change this
        for you.
      </li>
    </ul>

    <p>
      Augmented Knowledge Corp. will not lease, sell or distribute your personal
      information to any third parties unless we have your permission. We might
      do so if the law forces us. Your data will be used when we need to send
      your promotional materials if you agree to this privacy policy.
    </p>
    <footer>
      <button @click="close" class="close-button">Close</button>
      <p>&nbsp;</p>
    </footer>
  </section>
</template>

<script>
export default {
  name: "PrivacyPolicyAgreement",
  data() {
    return {
      // None
    };
  },
  methods: {
    close: function () {
      window.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  width: 100vw;
  height: 100vh;
  overflow: auto;
  scroll-behavior: smooth;
}

h2 {
  font-size: 1.3rem;
  font-weight: bold;
  text-align: center;
}

h4 {
  font-size: 1rem;
  font-weight: bold;
}

.close-button {
  cursor: pointer;
  margin: 0 calc(50% - 50px);
  width: 100px;
  height: 30px;
  border-radius: 5px;
  background-color: #666;
  color: white;
  font-family: sans-serif;
  font-weight: bold;
  box-shadow: none;
  border: none;
}
</style>
