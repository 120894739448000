<template>
  <div class="body">
    <AlertDialog ref="alertDialog">
      <h2>{{ alertMessage.title }}</h2>
      <pre>{{ alertMessage.message }}</pre>
    </AlertDialog>
    <AlertDialog ref="successToRegisterAlertDialog" @close="gotoHome">
      <h2>Success to Register</h2>
      <p>You must activate your account before login. Please check your email.</p>
    </AlertDialog>
    <div class="spinner" v-if="submitInProgress">
      <img src="@/assets/spinner.gif" alt="Submitting..." />
    </div>
    <div class="main-container" :class="{ submitting: submitInProgress }">
      <section class="col signup-area">
        <form class="signup-form">
          <p>
            <label for="fullname">Full Name <b>*</b></label>
            <input v-model="formData.displayName" type="text" name="displayname" placeholder="James Carter" required />
          </p>
          <p>
            <label for="email">Email <b>*</b></label>
            <input v-model="formData.email" type="text" name="email" placeholder="E-MAIL" required />
          </p>
          <p>
            <label for="password">Password <b>*</b></label>
            <input v-model="formData.password" type="password" name="password" placeholder="PASSWORD" required />
          </p>
          <p>
            <label for="confirm-password">Password Confirm <b>*</b></label>
            <input v-model="confirmPassword" type="password" name="confirm-password" placeholder="TYPE THE SAME ABOVE"
              required />
          </p>
          <p>
            <label for="company">Company</label>
            <input v-model="formData.company" type="text" name="company" placeholder="Blue Sky Airline" />
          </p>
          <p>
            <label for="title">Title</label>
            <input v-model="formData.title" type="text" name="title" placeholder="title" />
          </p>
          <p>
            <label for="phone">Phone</label>
            <input v-model="formData.phone" type="text" name="phone" placeholder="phone" />
          </p>
          <p>
            <label for="interest">Interest</label>
            <select v-model="formData.interest">
              <option value="Education">Education</option>
              <option value="Site Maintenance">Site Maintenance</option>
              <option value="Others">Others</option>
            </select>
          </p>
          <p class="privacy-policy">
            <input class="privacy-policy-agree-button" name="privacy-policy-agreement" type="checkbox"
              v-model="signUpButtonEnabled" @change="checkboxStateChanged" />
            <label for="privacy-policy-agreement" class="privacy-policy-agreement">Privacy Policy Agreement</label>
            <span> (<a href="/privacy" target="popup" @click="openPrivacyPolicyWindow">Read</a>)</span>
          </p>
          <div class="sign-up-button">
            <button class="button confirm button-sign " :class="{ disabled: !signUpButtonEnabled }" @click="register">
              <span>SIGN UP</span>
            </button>
            <button class="button cancel" @click="cancel">
              <span>Cancel</span>
            </button>
          </div>
        </form>
        <div class="signup-go">
          <span>Already have an account?</span>
          <a href="/signin">Sign in</a>
        </div>
      </section>
      <section class="singin-img">
        <img src="@/assets/signup.jpg" alt="">
      </section>
    </div>
  </div>
</template>

<script>
import { api } from "@/service/api";
import router from "@/router";
import AlertDialog from "@/components/AlertDialog.vue";

export default {
  name: "SignUpPage",
  props: [],
  emits: [],
  components: {
    AlertDialog,
  },
  data() {
    return {
      formData: {
        displayName: "",
        email: "",
        password: "",
        company: "",
        title: "",
        phone: "",
        interest: "Others",
      },
      confirmPassword: "",
      signUpButtonEnabled: false,
      alertMessage: {
        title: "",
        message: "",
      },
      submitInProgress: false,
    };
  },
  methods: {
    checkboxStateChanged() {
      // Do nothing
    },
    register(e) {
      e.preventDefault();
      const messages = [];
      if (this.formData.displayName?.trim().length < 3) {
        messages.push("Fullname must be longer than 3 characters.");
      }

      if (!/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i.test(this.formData.email?.trim())) {
        messages.push("Invalidate Email format.");
      }

      if (this.formData.password?.trim().length < 8) {
        messages.push("Password must be longer than 8 characters.");
      }

      if (this.formData.password !== this.confirmPassword) {
        messages.push("Password and Confirm password must be same.");
      }

      if (!this.signUpButtonEnabled) {
        messages.push("Please check the Privacy policy agreement.");
      }

      if (messages.length > 0) {
        this.alertMessage.title = "Failed to register";
        this.alertMessage.message = messages.reduce((a, m) => a + "- " + m + "\n", "");
        this.$refs.alertDialog?.open();
        this.submitInProgress = false;

        return false;
      }

      api.post("/register", this.formData).then((res) => {
        if (res.status < 400) {
          successToRegisterAlertDialog.value?.open();
          router.push("/");
        } else {
          this.alertMessage.title = "Failed to register";
          this.alertMessage.message = "Account already created or server error.";
          this.$refs.alertDialog?.open();
        }
      });
      this.submitInProgress = false;
      return false;
    },
    cancel(e) {
      e.preventDefault();
      router.back();
      return false;
    },
    gotoHome() {
      router.push("/");
    },
  },
};
</script>

<style lang="scss" scoped>
.main-container {
  display: flex;
  justify-content: center;
  height: 100%;
}

.main-container.submitting {
  pointer-events: none;
  opacity: 0.5;
}

button {
  border-radius: 50px;
  background-color: white;
  border: 1px solid #0F0E4C;
  color: #0F0E4C;
  text-align: center;
  font-size: 16px;
  padding: 10px;
  width: 150px;
  cursor: pointer;
  margin: 30px 0;
  font-weight: 700;
  cursor: pointer;
}

.spinner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 900;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100px;
    height: 100px;
  }
}

.button-sign {
  border-radius: 50px;
  background-color: #0F0E4C;
  border: 1px solid #0F0E4C;
  color: white;
  text-align: center;
  font-size: 16px;
  padding: 10px;
  width: 150px;
  cursor: pointer;
  margin: 30px 0;
  font-weight: 700;
  cursor: pointer;
}

.col {
  width: 50%;
  padding: 0 100px;
  margin: 120px 0;

  .signup-go {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    text-decoration: none;
    padding: 0 20px;
    margin-top: 20px;
    text-align: end;
    color: #bbb;

    a {
      color: #4d4d4d;
    }
  }

  .signup-form {
    background: #FFF;
    box-shadow: 2px 2px 15px 0px rgba(0, 0, 0, 0.10);
    padding: 40px;
  }

  p {
    width: 100%;
    padding-top: 10px;
  }

  label {
    display: block;
    color: #222;
    font-size: 18px;
    font-weight: 500;

    b {
      color: #F00;
      font-size: 15px;
      font-weight: 500;
    }

    @media (min-width: 1024px) {}

    &.privacy-policy-agreement {
      padding: 0 5px;
      text-overflow: ellipsis;
      font-size: 1em !important;
    }
  }

  input,
  select {
    margin-top: 5px;
    padding: 10px 5px;
    display: block;
    width: 100%;
    font-size: 16px;
    border: none;
    border-bottom: 1px solid #bbb;

    &::placeholder {
      @extend %my-placeholder;
    }
  }
}

%my-placeholder {
  color: #9E9E9E;
  font-size: 14px;
  font-weight: 400;
}

.singin-img {
  width: 50%;
  height: calc(100vh - 87px);
  background-image: url("@/assets/signup.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  position: sticky;
  top: 87px;

  img {
    width: 100%;
    height: 100%;
  }
}

.signup-area {
  .privacy-policy {
    padding-top: 20px;
    font-size: 1.2rem;
    display: flex;
    justify-content: flex-start;

    label {
      margin: 0 8px;
      font-size: 16px;
      color: #666;
      font-weight: 400;
    }

    input {
      width: 24px;
      margin: 0;
      padding: 0;
    }
  }

  .sign-up-button {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: bottom;
    gap: 20px;
    height: auto;

    button {
      width: 100%;
    }

    .confirm.disabled {
      opacity: 1;
    }
  }
}

@media(max-width: 1024px) {
  .singin-img {
    display: none;
  }

  .col {
    width: 100%;
    padding: 0 20px;
    margin: 60px 0;
  }
}
</style>
