<template>
  <div class="main">
    <h3>Pricing</h3>
    <p>Build your Package by picking best plans</p>
    <span class="web-version">Web Version</span>
    <section class="cards">
      <div class="card">
        <h3 class="duration">1 Month</h3>
        <p class="price1">$495 / user / aircraft</p>
        <p class="price2">&nbsp;</p>
        <ul class="features">
          <li>Private Account</li>
          <li>Full HD</li>
          <li>Available on Android OS Smart Phone</li>
          <li>Tablet PC</li>
          <li>PC</li>
          <li>Cloud Service</li>
        </ul>
      </div>
      <div class="card">
        <h3 class="duration">3 Months</h3>
        <p class="price1">$1,395 / user / aircraft</p>
        <div class="tax-wrap">
          <p class="price2">$1,485 / user / aircraft</p>
          <span>(6% off)</span>
        </div>
        <ul class="features">
          <li>Private Account</li>
          <li>Full HD</li>
          <li>Available on Android OS Smart Phone</li>
          <li>Tablet PC</li>
          <li>PC</li>
          <li>Cloud Service</li>
        </ul>
      </div>
      <div class="card">
        <h3 class="duration">1 Year</h3>
        <p class="price1">$5,395 / user / aircraft</p>
        <div class="tax-wrap">
          <p class="price2">$5,940 / user / aircraft</p>
          <span>(10% off)</span>
        </div>
        <ul class="features">
          <li>Private Account</li>
          <li>Full HD</li>
          <li>Available on Android OS Smart Phone</li>
          <li>Tablet PC</li>
          <li>PC</li>
          <li>Cloud Service</li>
        </ul>
      </div>
      <div class="card">
        <h3 class="duration blue">MR (HoloLens II)</h3>
        <ul class="features mr">
          <li>Voice Recognition</li>
          <li>Object Recognition</li>
        </ul>
        <p class="mr-p">
          If you are interested in MR, VR, PC<br />versions, please
          <a href="/contact">contact us</a> for more<br />price options.
        </p>
        <div class="main-btn">
          <a href="/pricing/mr-demo-video" class="mr-demo-video-button">MR DEMO VIDEO</a>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup lang="ts">
</script>

<style lang="scss">
.main {
  max-width: 1600px;
  padding: 80px 20px;
  margin: 0 auto;

  h3 {
    color: #0F0E4C;
    font-size: 40px;
    font-weight: 800;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
  }

  p {
    display: block;
    margin-top: 15px;
    margin-bottom: 20px;
    color: #333;
    font-size: 18px;
    font-weight: 400;
  }

  .web-version {
    display: block;
    margin-top: 60px;
    margin-bottom: 10px;
    color: #0F0E4C;
    font-size: 24px;
    font-weight: 800;
  }
}

img.diagram1 {
  width: 60vw;
  min-width: 1024px;
  height: auto;
  margin: 30px 0;
}

.cards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 50px;
  width: 100%;
}

.card {
  border: 1px solid #bbb;
  text-align: center;

  .features {
    margin-top: 60px;
    margin-bottom: 50px;

    li {
      padding: 6px 0;
    }
  }

  .tax-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    span {
      color: #333;
      font-size: 16px;
      font-weight: 400;
    }
  }

  .duration {
    background: #333;
    color: white;
    font-weight: 400;
    font-size: 20px;
    padding: 10px 0;
  }

  .price1 {
    color: #333;
    text-align: center;
    font-size: 34px;
    font-weight: 600;
    margin-top: 30px;
  }

  .price2 {
    text-decoration: line-through;
    color: #333;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    padding: 0;
  }

  ul {
    list-style-type: "√ ";
    text-align: left;

    li:hover {
      font-weight: bold;
    }
  }

  .mr-p {
    text-align: left;
    padding: 0 30px;
    font-size: 18px;

    a {
      text-decoration: none;
      color: #333333;
    }
  }

  .main-btn {
    margin-top: 120px;
  }
}

.duration.blue {
  background: #0F0E4C;
  ;
}

.mr-demo-video-button {
  width: 90%;
  border-radius: 50px;
  border: 1px solid #0F0E4C;
  background: #FFF;
  padding: 1em 6em;
  margin: 0 3em;
  text-decoration: none;
  white-space: nowrap;
}

footer {
  position: relative;
  padding-top: 30px;
  bottom: 0;
  text-align: center;
  font-family: sans-serif;
  font-size: 0.8rem;
  font-weight: bold;
  line-height: 0.3rem;
  height: 50px;
}

@media (max-width: 1020px) {

  .main {
    padding: 80px 20px;

    .cards {
      grid-template-columns: repeat(2, 1fr);

      .main-btn {
        margin-top: 60px;
        padding: 30px 0;
      }
    }
  }

}


@media (max-width: 700px) {

  .main {
    padding: 80px 20px;

    .cards {
      grid-template-columns: repeat(1, 1fr);

      .main-btn {
        margin-top: 60px;
        padding: 30px 0;
      }
    }
  }

}
</style>
