<template>
  <h6>Logout. Please wait...</h6>
</template>

<script>
import { useStore } from "@/store";
import { api } from "@/service/api";
import router from "@/router";

async function signOut() {
  const store = useStore();
  window.sessionStorage.removeItem("token");
  window.localStorage.removeItem("refreshToken");
  store.user = null;
}

export default {
  name: "SignOutPage",
  props: [],
  emits: [],
  data() {
    return {};
  },
  async mounted() {
    await api.post("/logout");
    await signOut();
    router.push("/");
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
h6 {
  font-size: 2rem;
  font-weight: normal;
}
</style>
