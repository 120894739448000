<template>
  <section class="main">
    <div class="description">
      <p>Our MR content was developed for interaction between AI and users.</p>
      <p>
        Knowledge-based AI shows the process of aviation MRO in real-time in 3D
        form through Hololens II. With our MR content, users can understand,
        train, and process the work of the aviation MRO in XR(eXtended reality).
      </p>
      <p>
        Since this content is based on the official manual documented for
        specific aviation models, users no longer have to cross-check hundreds
        of thousands of paper manuals. Users can select and follow each of the
        sub-task steps of the main tasks, improve work efficiency, and reduce
        maintenance errors.
      </p>
      <p>Click on the video below to see how the MR content works.</p>
    </div>
    <div class="videos">
      <div class="card">
        <figure>
          <iframe width="560" height="315" src="https://www.youtube.com/embed/m_3tPA-f90w" title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>
        </figure>
        <figcaption>
          <h4>AK GO - B737 Main Landing Gear Removal</h4>
          <p>
            (<span style="color: orange">★</span>Awarded 2021 Korea Unity
            Contest)
          </p>
          <br />
          <p>
            MRO manuals, IPC view, AMM view, on-site maintenance reference
            videos, warnings, caution messages, and notes, voice recognition,
            object recognition are available.
          </p>
        </figcaption>
      </div>
      <div class="card">
        <figure>
          <iframe width="560" height="315" src="https://www.youtube.com/embed/PBElZip1v3A" title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>
        </figure>
        <figcaption>
          <h4>B737 Main Landing Gear Brake and Tire Assembly</h4>
          <br />
          <p>
            MRO manuals, IPC view, AMM view, on-site maintenance reference
            videos, warnings, caution messages, and notes, voice recognition,
            object recognition are available.
          </p>
        </figcaption>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: [],
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  max-width: 1920px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  overflow: hidden;

  .description {
    flex: 1;
    font-size: 1.5rem;
    max-width: 1280px;
    color: inherit;
    background-color: transparent;
  }

  .videos {
    flex: 1;
    display: flex;
    flex-direction: column;
    row-gap: 50px;

    .card {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;

      max-width: 1280px;

      figure {
        width: 560px;
      }

      figcaption {
        width: 600px;
        padding: 30px;

        h4 {
          padding: 0;
          margin: 0;
        }
      }
    }
  }
}
</style>
