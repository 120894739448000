<template>
  <div class="subtasklist-wrapper">
    <ul class="subtasklist">
      <li v-for="(task, idx) in subtasks" :key="idx">
        <a :href="task.link" v-bind:class="{ bold: task.label === currentTask }">{{ task.label }}</a>
      </li>
    </ul>
  </div>
</template>

<script>
import { api } from "@/service/api";
export default {
  name: "SubTaskList",
  props: {
    model: {
      type: String,
      required: true,
    },
    currentTask: {
      type: String,
      required: true,
    },
    embed: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: [],
  data() {
    return {
      subtasks: [],
    };
  },
  computed: {
    task: function () {
      return this.currentTask.split(" ")[0];
    },
  },
  watch: {
    task: async function (taskId) {
      this.subtasks = (
        await api.get(`/index/components/${this.model}/${taskId}`)
      ).data;

      if (this.embed) {
        this.subtasks = this.subtasks.map((task) => {
          task.link = task.link.replace("/watch/", "/embed/");
          return task;
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.subtasklist-wrapper {
  overflow-y: scroll;
  padding: 5px;
}

h2 {
  font-size: 14px;
}

.subtasklist {
  list-style-type: disc;
  margin: 0;
  padding: 0 15px;

  a {
    line-height: 1.2em;
    font-size: 1.6em;
    text-decoration: none;

    &.bold {
      font-weight: bold;
    }

    &:hover {
      color: purple;
      font-weight: bold;
    }
  }
}
</style>
