<template>
  <div
    :class="['popup', position]"
    v-bind:style="styleOverride"
    ref="rootElement"
  >
    <header @mousedown="dragStart" @mouseup="dragEnd">
      <button
        class="close-button"
        @mousedown="noOp"
        @mouseup="noOp"
        @click="hideAnnotation"
      >
        ×
      </button>
    </header>
    <div class="popup-content">
      <slot />
    </div>
  </div>
</template>

<script>
/// Utility
function getCurrentMousePosition() {
  return [window.event.pageX, window.event.pageY];
}

export default {
  name: "overlayPopupWindow",
  props: {
    position: {
      default: "right",
    },
  },
  emits: ["close"],
  data() {
    return {
      dragging: false,
      dragOffset: [0, 0],
      styleOverride: {},
      dragHandler: () => {},
    };
  },
  computed: {},
  methods: {
    hideAnnotation() {
      this.$emit("close");
    },

    reset() {
      this.dragging = false;
      this.styleOverride = {};
    },

    dragStart() {
      const box = this.$refs["rootElement"].getBoundingClientRect();
      console.log(box);
      const mousePos = getCurrentMousePosition();
      this.dragging = true;
      this.dragOffset = [mousePos[0] - box.x, mousePos[1] - box.y];

      console.log(this.dragOffset);

      this.styleOverride = {
        position: "fixed",
        left: box.x + "px",
        right: "inherit",
        top: box.y + "px",
        width: box.width + "px",
        height: box.height + "px",
      };
    },
    drag(e) {
      if (!this.dragging) return;
      if ((e.buttons & 0x01) !== 0x01) {
        this.dragging = false;
        return false;
      }

      e.preventDefault();
      const offset = this.dragOffset;
      const curPos = getCurrentMousePosition();

      const diff = [curPos[0] - offset[0], curPos[1] - offset[1]];
      this.styleOverride = {
        ...this.styleOverride,
        left: diff[0] + "px",
        top: diff[1] + "px",
      };
    },
    dragEnd() {
      this.dragging = false;
    },

    noOp(e) {
      e.stopPropagation();
      return false;
    },
  },

  mounted() {
    window.addEventListener("mousemove", this.drag);
  },

  beforeUnmount() {
    window.removeEventListener("mousemove", this.drag);
  },
};
</script>

<style lang="scss" scoped>
.popup {
  position: absolute;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  top: 10px;
  min-width: 100px;
  max-width: 45%;
  min-height: 30%;
  max-height: 90%;
  overflow: scroll;
  border: 3px solid #333;
  border-radius: 10px;
  background-color: #fff;

  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  &.right {
    right: 10px;
  }

  &.left {
    left: 10px;
  }
}

header {
  display: flex;
  flex-basis: 32px;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
  height: 32px;
}

.close-button {
  top: 2px;
  right: 12px;
  width: 32px;
  height: 30px;
  border: none;
  border-radius: 3px;
  color: #222;
  background-color: transparent;
  z-index: 30;
  font-family: monospace;
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 2px;
  text-align: center;
  margin: 0;
  margin-left: auto;
  padding: 1px;

  &:hover {
    background-color: #e33;
  }
}

.popup-content {
  display: flex;
  flex: 1 1 0%;
  overflow: auto;
}

.popup-content :deep() > * {
  flex: 1 1 0%;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
}

.popup-content :deep(.link-url) {
  display: block;
  width: 100%;
  text-align: center;
  font-size: 25px;
  font-weight: bold;
  color: black;
  background-color: #eee;
}

.popup-content :deep(.fit-container) {
  display: block;
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
}

.popup-content :deep(.note) {
  display: block;
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
}

.popup-content :deep(iframe) {
  width: 100%;
  height: 100%;
  overflow: visible;
}
</style>
