<template>
  <div class="wrapper">
    <annotation-canvas :videoId="src"></annotation-canvas>
  </div>
</template>

<script>
import AnnotationCanvas from "@/components/AnnotationCanvas";

export default {
  name: "annotation-tool",
  // title: '',
  components: {
    AnnotationCanvas,
  },
  props: ["id"],
  computed: {
    title: function () {
      return this.id;
    },
    src: function () {
      return this.id;
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  position: relative;
  width: 100%;
  height: calc(100vh - 90px);
  overflow: hidden;
}
</style>
