import { createApp } from "vue";
import { createPinia } from "pinia";
import PiniaPersist from "pinia-plugin-persist";

import App from "./App.vue";
import router from "./router";
import { api } from "@/service/api";

const app = createApp(App);
const pinia = createPinia();
pinia.use(PiniaPersist);

app.use(router);
app.use(pinia);

app.mount("#app");

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
app.config.globalProperties = {
  ...app.config.globalProperties,
  $http: api,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  baseURL: process.env.BASE_URL! + "api",
};
