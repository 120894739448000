<template>
  <div class="annotation-bullet-bar">
    <div class="left-pane">
      <svg version="1.1" baseProfile="full" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg"
        ref="timeSlider">
        <g>
          <!-- time indicator -->
          <text x="10" y="115" class="time-range">
            {{ toTime(currentTime) }} / {{ toTime(duration) }}
          </text>
          <rect x="0" y="125" width="100%" height="5" fill="rgba(255,255,255,0.7)" stroke="rgba(127,127,127,0.7)" rx="2"
            ry="2" />
          <!-- seeker expension -->
          <rect @click="seek" @mousemove="dragSeek" x="0" y="117" width="100%" height="20" fill="transparent"
            stroke="transparent" class="clickable" />
          <g v-for="(bullet, i) in annotationBullets" :key="i" @mouseleave="hoveredBullet = -1" class="clickable">
            <circle :cx="bullet.x" :cy="127" r="5" fill="#fff" @mouseenter="hoveredBullet = i" @click="jumpTo(i)" />
            <!-- <image v-bind="bullet" v-show="hoveredBullet === i" /> -->
          </g>
          <circle :cx="currentTimeBulletPosition" cy="127" r="10" fill="#e46262" />
        </g>
      </svg>
    </div>
    <div class="right-pane">
      <input type="range" class="clickable volume-slider" v-model="volume" @input="onVolumeChanged"
        @change="onVolumeChanged" :min="0" :max="100" :step="1" />
      <label class="switch clickable auto-continue">
        <input type="checkbox" v-model="store.autoContinue" />
        <span class="slider round">Auto Continue</span>
      </label>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { useStore } from "@/store";
import { mapState } from "pinia";

export default {
  name: "seeker-bar",
  props: ["annotation", "currentTime", "duration", "width"],
  emits: ["seek", "autoContinue", "volumeChange"],

  data() {
    return {
      volume: 100,
      baseURL: process.env.BASE_URL,
      hoveredBullet: -1,
      store: useStore(),
    };
  },

  computed: {
    timeSliderWidth() {
      // 150px is defined at the css right-pane class
      return this.width - 150;
    },

    annotationBullets() {
      const { header, metadataObject } = this.annotation;

      if (!header || !metadataObject) return;

      const bullets = [];

      if (header.version >= 2) {
        for (const annotation of metadataObject) {
          const position =
            ((annotation.timeRange?.[0] ?? NaN) / this.duration) *
            this.timeSliderWidth;

          if (!_.isFinite(position)) break;

          bullets.push({
            x: position,
            href: `${this.baseURL}api/video/${header.owner}/${header.id}/annotation/${annotation.img}`,
            width: 150,
            height: 120,
          });
        }
      } else {
        console.error(
          "[WARNING] Unsupported annotation version",
          header.version
        );
      }

      return bullets;
    },

    currentTimeBulletPosition() {
      const pos = (this.currentTime / this.duration) * this.timeSliderWidth;

      return _.isFinite(pos) ? pos : 0;
    },

    ...mapState(useStore, ["autoContinue"]),
  },

  watch: {
    autoContinue(newVal) {
      this.$emit("autoContinue", newVal);
    },
  },

  methods: {
    seek(e) {
      const { x, width } = e.target.getBoundingClientRect();
      const p = (e.clientX - x) / (width - x);

      this.$emit("seek", this.duration * p);
    },

    dragSeek(e) {
      if (e.buttons === 1) {
        const { x, width } = e.target.getBoundingClientRect();
        const p = (e.clientX - x) / (width - x);

        this.$emit("seek", this.duration * p);
      }
    },

    jumpTo(i) {
      this.$emit(
        "seek",
        (this.duration * this.annotationBullets[i].x) / this.timeSliderWidth
      );
    },

    toTime(num) {
      const pad = (n) => (n < 10 ? "0" + n : "" + n);

      let t = num;
      const hour = parseInt(t / 60 / 60);
      t = t - hour * 60 * 60;
      const min = parseInt(t / 60);
      t = t - min * 60;
      const sec = parseInt(t);

      let text = hour > 0 ? `${hour}:` : "";
      text = text + pad(min) + ":";
      text = text + pad(sec);

      return text;
    },

    onVolumeChanged(e) {
      this.$emit("volumeChange", e.target.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.annotation-bullet-bar {
  pointer-events: none;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;

  .clickable {
    pointer-events: auto;
  }

  .time-range {
    font-size: 0.7rem;
    font-weight: 700;
  }

  .left-pane {
    flex: 1 0;
  }

  .right-pane {
    flex: 0 0 150px;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: auto;
    margin-left: auto;

    .volume-slider {
      width: 100px;
      height: 30px;
      transform: rotate(-90deg) translateX(30px) translateY(35px);
    }

    .auto-continue {
      margin-right: 30px;
      margin-bottom: 12px;

    }
  }
}
</style>
