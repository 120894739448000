<template>
  <div class="container">
    <section ref="videoWrapper" class="video-view">
      <annotation-video-player ref="videoPlayer" :videoId="videoId" :width="componentWidth" :height="componentHeight" @timeupdate="timeUpdate" />
    </section>
  </div>
</template>

<script>
import AnnotationVideoPlayer from "@/components/AnnotationVideoPlayer";

export default {
  components: {
    AnnotationVideoPlayer,
  },
  props: {
    videoId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      componentWidth: 0,
      componentHeight: 0,
      items: [],
      currentTime: 0,
      syncMessageEmitter: null,
    };
  },

  methods: {
    adjustWindow() {
      const { width, height } = this.$refs.videoWrapper.getBoundingClientRect();
      this.componentWidth = width;
      this.componentHeight = height;
    },

    timeUpdate(currentTime) {
      this.currentTime = currentTime;
    },
  },

  mounted() {
    window.addEventListener("resize", this.adjustWindow);
    this.adjustWindow();
  },

  beforeUnmount() {
    window.removeEventListener("resize", this.adjustWindow);
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100vw;
  max-width: 100%;
  min-height: 100%;
  overflow: auto;
  padding: 0;

  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  align-content: center;
  justify-content: space-between;

  background-color: #eee;

  section {
    display: block;
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }

  .video-view {
    min-height: 100vh;
    overflow: hidden;
  }
}
</style>
