<template>
  <div class="video-wrapper" ref="rootContainer">
    <AlertDialog ref="alertDialogFailedToLoadAnnotation">
      <h2>Access Denied</h2>
      <p>This content is not available for a Guest user.<br />
        Please <a href="/contact" target="popup">contact us</a> to
        explore AK View</p>
    </AlertDialog>

    <FeedbackModal @close="closeFeedbackModal" :show="feedbackModalOpened" />
    <!-- <div class="feedback-button">
      <img src="@/assets/Group30.png" @click="openFeedbackModal" />
    </div> -->
    <div class="video-window" :style="{
      width: videoComponentWidth + 'px',
      height: videoComponentHeight + 'px',
    }">
      <video class="overlay" controlsList="nodownload" ref="videoComponent" preload="auto" playsinline
        @loadedmetadata="onLoadedMetadata" @loadeddata="onLoadedData" @click="annotationHandler" @ended="playNextVideo">
        <source :src="selectedVideoURL" :type="videoType" />
        <source :src="`${videoURL}?token=${userToken}`" :type="videoType" />
      </video>
      <div class="overlay" v-show="overlayEnabled" :style="{ pointerEvents: 'none' }">
        <div class="annotation">
          <div v-for="(item, key) in annotationRects" :key="key" class="overlay-tag" :style="item.style"
            @click="() => showAnnotation(item)" @error="hideIcon">
            <img v-if="item.src && item.src.length > 0" :src="item.src" />
            <h5 v-if="!item.src">{{ item.label }}</h5>
          </div>
          <div class="subtask-list-button" :style="{ ...subtaskButtonPosition }" @click="toggleSubTaskList">
            <transition name="fade">
              <SubTaskList v-show="subTaskListVisible" :model="model" :currentTask="taskId" class="subtask-list" />
            </transition>
          </div>
        </div>
      </div>
      <div class="overlay model-name">
        <h3>{{ model }}</h3>
      </div>
      <seeker-bar class="seeker-bar" @seek="seek" @volumeChange="onVolumeChanged" :annotation="annotation"
        :currentTime="currentTime" :duration="duration" :width="videoComponentWidth" :volume="volume" />
      <overlay-popup-window class="overlay-popup-window" ref="overlayPopupWindow" :position="overlayAnchorPosition"
        @close="hideAnnotation" v-show="overlayPopupVisible && overlayEnabled">
        <div v-html="overlayPopup"></div>
      </overlay-popup-window>
    </div>
  </div>
</template>

<script>
import { isFinite, isObject, get, filter, findIndex, findLastIndex } from "lodash";
import { useStore } from "@/store";
import { mapState } from "pinia";
import { api } from "@/service/api";
import router from "@/router";


import SeekerBar from "./SeekerBar.vue";
import SubTaskList from "../SubTaskList.vue";
import OverlayPopupWindow from "./OverlayPopupWindow.vue";
import FeedbackModal from "@/components/FeedbackModal.vue";
import AlertDialog from "@/components/AlertDialog.vue";

export default {
  components: {
    SeekerBar,
    SubTaskList,
    OverlayPopupWindow,
    FeedbackModal,
    AlertDialog,
  },
  props: ["videoId", "width", "height"],
  emits: ["resize", "timeupdate", "bookrefupdate"],
  data() {
    return {
      videoWidth: 320,
      videoHeight: 240,
      videoScale: 1.0,
      currentTime: 0,
      duration: 0,
      videoComponentWidth: 0,
      videoComponentHeight: 0,

      volume: 100,

      model: "",
      taskId: "",
      title: "[Untitled]",
      annotation: {},
      annotationRects: {},
      tickTimer: null,
      overlayAnchorPosition: "right",

      currentShowingAnnotationId: null,
      overlayPopup: "",
      overlayPopupVisible: false,

      // SubTaskList
      subTaskListVisible: false,

      // Feedback
      feedbackModalOpened: false,
    };
  },

  computed: {
    ...mapState(useStore, {
      autoContinueStatus: "autoContinue",
      overlayEnabled: "showAnnotation",
    }),
    userToken() {
      return window.sessionStorage.getItem("token");
    },

    videoURL() {
      return process.env.BASE_URL + `api/video/${this.videoId}`;
    },

    videoType() {
      return "video/mp4";
    },

    subtaskButtonPosition() {
      const left = 0.919271 * this.videoComponentWidth + "px";
      const top = 0.850642 * this.videoComponentHeight + "px";
      const width = 0.060125 * this.videoComponentWidth + "px";
      const height = 0.100188 * this.videoComponentHeight + "px";

      return {
        top,
        left,
        width,
        height,
        // border: "3px solid red",
      };
    },

    selectedVideoURL() {
      let quality = '720p'; // Default value

      if (this.width >= 3840 && this.height >= 2160) quality = '2160p';
      else if (this.width >= 2560 && this.height >= 1440) quality = '1440p';
      else if (this.width >= 1920 && this.height >= 1080) quality = '1080p';
      else if (this.width >= 1280 && this.height >= 720) quality = '720p';
      else if (this.width >= 854 && this.height >= 480) quality = '480p';
      else if (this.width >= 640 && this.height >= 360) quality = '360p';
      else if (this.width >= 426 && this.height >= 240) quality = '240p';

      return `${this.videoURL}?token=${this.userToken}&quality=${quality}`;
    }
  },

  watch: {
    async videoId() {
      this.overlayPopupVisible = false;
      this.$refs["overlayPopupWindow"].reset();
      this.$refs.videoComponent.load();
      await this.loadAnnotation();
    },

    width() {
      this.adjustWindow();
    },

    height() {
      this.adjustWindow();
    },
  },

  methods: {
    // Video Controls
    onLoadedMetadata() {
      const el = this.$refs.videoComponent;
      this.duration = el.duration;
      this.adjustWindow();

      const url = new URL(window.location);

      if (url.searchParams.has("ts")) {
        const seekTo = parseFloat(url.searchParams.get("ts"));
        if (isFinite(seekTo)) {
          this.seek(seekTo);
          el.pause();
        }
      } else {
        // Prevent to throw autoplay error
        el.play().then(() => { }).catch(() => { });
      }
    },
    onLoadedData() {
      // 2022-07-26 Added: url query 사전지정시 어노테이션 강제 open
      const url = new URL(window.location);

      if (url.searchParams.has("a")) {
        const { metadataObject } = this.annotation;

        const openAnnotation = _(metadataObject)
          .filter((o) => {
            return o.name === decodeURIComponent(url.searchParams.get("a"));
          })
          .last();

        if (isObject(openAnnotation)) {
          this.showAnnotation(this.annotationRects[openAnnotation.id] || openAnnotation);
        }
      }
    },
    onVolumeChanged(newVolume) {
      const volume = parseFloat(newVolume);
      this.volume = volume;
      this.$refs.videoComponent.volume = volume / 100.0;
      this.$refs.videoComponent.muted = volume < 1.0;
    },

    async loadAnnotation() {
      // Clear previous data
      this.annotation = {};
      this.annotationRects = {};

      const res = await api.get(`/video/${this.videoId}/annotations`);
      if (res.status >= 400) {
        this.$refs.alertDialogFailedToLoadAnnotation.value?.open();
        return;
      }

      this.annotation = res.data;

      // Common
      const [model, taskId] = this.videoId.split("/");
      this.title = `[${model}] ${taskId}`;
      // this.title = this.annotation?.header?.title ?? "[Untitled]";
      this.model = model;
      this.taskId = taskId;

      if (this.tickTimer) window.cancelAnimationFrame(this.tickTimer);

      if (this.annotation?.header?.version >= 2) {
        this.tickTimer = window.requestAnimationFrame(this.tick);
      }
    },

    adjustWindow() {
      this.videoWidth = this.$refs.videoComponent.videoWidth || 0;
      this.videoHeight = this.$refs.videoComponent.videoHeight || 0;

      const { width: containerWidth, height: containerHeight } = this.$refs.rootContainer.getBoundingClientRect();
      let scale = containerWidth / (this.videoWidth + 0.0001);
      if (this.videoHeight * scale > containerHeight) {
        scale = containerHeight / (this.videoHeight + 0.0001);
      }

      this.videoComponentWidth = this.videoWidth * scale;
      this.videoComponentHeight = this.videoHeight * scale;
      this.videoScale = scale;
    },

    hideIcon(e) {
      e.target.style.visibility = "hidden";
    },

    async showAnnotation(item) {
      if (this.currentShowingAnnotationId === item.label) return;
      console.log(`Load Annotation`, item);
      this.currentShowingAnnotationId = item.label;
      // Change behavior from 2022-04-28: Keep going video
      // const video = this.$refs.videoComponent;
      // video.pause();
      console.time("load annotation");
      const res = await api.get(`/video/${this.videoId}/annotation/${item.content}`);
      console.timeEnd("load annotation");

      if (res.status < 400) {
        const data = res.data;

        // Javascript execution override
        if (data.trim().startsWith("http")) {
          window.location = data;
          return;
        } else if (data.trim().startsWith("/watch/")) {
          // Workaround fix
          this.currentShowingAnnotationId = null;
          this.annotationRects = {};
          router.push(data);
          return;
        }

        this.overlayPopup = data;
        this.overlayPopupVisible = true;
        this.overlayAnchorPosition = get(item, "bbox.norm[0]", 0) < 0.5 ? "right" : "left";
      }
    },

    hideAnnotation() {
      this.overlayPopupVisible = false;
      this.currentShowingAnnotationId = null;
    },

    /// Utility
    getCurrentMousePosition() {
      const anchor = this.$refs.videoComponent.getBoundingClientRect();
      return [window.event.pageX - anchor.left, window.event.pageY - anchor.top];
    },

    annotationHandler(e) {
      e.preventDefault();
      const [x, y] = this.getCurrentMousePosition();
      console.log("AbsPos", [x, y], "RelPos", [x / this.videoComponentWidth, y / this.videoComponentHeight]);

      this.playPause();
    },

    tick() {
      const { currentTime } = this.$refs.videoComponent;
      this.currentTime = currentTime;
      this.$emit("timeupdate", currentTime);

      const { metadataObject } = this.annotation;

      if (!metadataObject) {
        this.tickTimer = window.requestAnimationFrame(this.tick);
        return;
      }

      const inArr = filter(metadataObject, (o) => {
        return o.timeRange[0] <= currentTime && o.timeRange[1] >= currentTime;
      });
      const outArr = filter(metadataObject, (o) => {
        return o.timeRange[0] > currentTime || o.timeRange[1] < currentTime;
      });

      const { width: videoWidth, height: videoHeight } = this.$refs.videoComponent.getBoundingClientRect();

      // Incoming
      for (const inObj of inArr) {
        const src = inObj.img;
        const style = {
          position: "absolute",
        };

        if (src) {
          // No bounding box
          style.border = "none";
        }

        const i1 = findLastIndex(inObj.bbox, (o) => o[0] <= currentTime);
        const i2 = findIndex(inObj.bbox, (o) => o[0] >= currentTime);

        const [s1, [p1x1, p1y1], [p1x2, p1y2]] = inObj.bbox[i1];
        const [s2, [p2x1, p2y1], [p2x2, p2y2]] = inObj.bbox[i2];

        const t = (currentTime - s1) / (s2 - s1);

        const left = p1x1 + (p2x1 - p1x1) * t;
        const top = p1y1 + (p2y1 - p1y1) * t;
        const right = p1x2 + (p2x2 - p1x2) * t;
        const bottom = p1y2 + (p2y2 - p1y2) * t;
        const width = right - left;
        const height = bottom - top;

        style.left = `${left * videoWidth}px`;
        style.top = `${top * videoHeight}px`;
        style.width = `${width * videoWidth}px`;
        style.height = `${height * videoHeight}px`;

        this.annotationRects[inObj.id] = {
          src: (src && `${this.videoURL}/annotation/${src}`) || null,
          style: style,
          label: inObj.name,
          content: inObj.content,
          bbox: {
            norm: [left, top, right, bottom],
            abs: [left * videoWidth, top * videoHeight, right * videoWidth, bottom * videoHeight],
          },
        };
      }

      // Outgoing
      for (const outObj of outArr) {
        delete this.annotationRects[outObj.id];
      }

      this.tickTimer = window.requestAnimationFrame(this.tick);
    },

    seek(time) {
      this.$refs.videoComponent.currentTime = time;
    },

    playPause() {
      const video = this.$refs.videoComponent;

      if (video.paused) {
        video.play();
        this.hideAnnotation();
      } else {
        video.pause();
      }

      // Special override: Hide subtask list
      this.subTaskListVisible = false;
    },

    play() {
      const video = this.$refs.videoComponent;
      if (video) {
        video.play();
      }
    },

    pause() {
      const video = this.$refs.videoComponent;
      if (video) {
        video.pause();
      }
    },

    goEdit() {
      router.push("/edit/" + this.videoId);
    },

    likeVideo() {
      api.post(`/video/${this.videoId}/like`);
    },

    dislikeVideo() {
      api.post(`/video/${this.videoId}/dislike`);
    },

    // Notification Modal
    hideNoticeModal() {
      this.noticeModalVisible = false;
    },

    // SubTaskList
    toggleSubTaskList() {
      this.subTaskListVisible = !this.subTaskListVisible;
    },

    playNextVideo() {
      if (this.autoContinueStatus === false) return;

      const { metadataObject } = this.annotation;

      const nextAnnotation = _(metadataObject)
        .filter((o) => {
          return /^Next$/i.test(o.name);
        })
        .last();

      if (isObject(nextAnnotation)) {
        this.showAnnotation(nextAnnotation);
      }
    },

    openFeedbackModal() {
      this.feedbackModalOpened = true;
      this.pause();
    },

    closeFeedbackModal() {
      this.feedbackModalOpened = false;
    },
  },

  created() {
    const url = new URL(window.location);
    const store = useStore();
  },

  async mounted() {
    this.$refs.videoComponent.disablePictureInPicture = true;
    // Autoplay if modal is now shown
    if (this.noticeModalVisible === false) {
      this.$refs.videoComponent.autoplay = true;
    }

    await this.loadAnnotation();
  },

  unmounted() {
    this.pause();
    window.cancelAnimationFrame(this.tickTimer);
  },
};
</script>

<style lang="scss" scoped>
.video-wrapper {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #999;
  margin: 0 auto;

  .video-window {
    position: relative;
    width: 100%;
  }

  .next-video {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 1px;
    height: 1px;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background-size: cover;
    overflow: hidden;
    object-fit: fill;

    img {
      object-fit: cover;
    }
  }

  .model-name {
    pointer-events: none;

    h3 {
      font-family: "Roboto", sans-serif;
      font-size: 35px;
      color: white;
      margin: 0;
      padding: 10px 10px;
    }
  }

  .annotation {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: visible;

    .overlay-tag {
      /* border: 3px solid blue; */
      position: absolute;
      pointer-events: all;
      cursor: pointer;
      object-fit: contain;

      &:hover {
        border: 3px solid rgba(0, 0, 255, 0.3);
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    h5 {
      visibility: hidden;
      color: white;
      line-height: 1.5em;
      background-color: blue;
      margin: 0;
      padding: 0;
    }
  }

  .seeker-bar {
    position: absolute;
    width: 100%;
    height: 150px;
    left: 0;
    bottom: 0px;
    z-index: 10;
  }
}

.overlay-popup-window {
  z-index: 100;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
  {
  opacity: 0;
}

.inline {
  display: flex;
  justify-content: flex-start;
}

.edit-button {
  margin-left: 10px;
}

.subtask-list-button {
  position: absolute;
  box-sizing: border-box;
  pointer-events: all;
  cursor: pointer;
}

.subtask-list {
  position: absolute;
  pointer-events: all;
  box-sizing: border-box;
  bottom: 0;
  right: 0;
  width: 40vw;
  height: 30vh;
  border: 3px solid black;
  background: white;
  opacity: 0.8;
  z-index: 32;

  @media (max-width: 800px) {
    width: 80vw;
  }

  a {
    font-size: 1em;
    text-decoration: none;

    &:hover {
      font-weight: bold;
    }
  }
}

.feedback-button {
  position: absolute;
  top: 100px;
  right: 60px;
  width: 55px;
  height: 49px;
  z-index: 30;
  cursor: pointer;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
